import { NavLink } from "@remix-run/react";
import SocialMedia from "@/components/SocialMedia";

interface IFooter {
    logo: any;
    navigationLeft: any;
    navigationRight: any;
    locale: string;
    copyright: any
}

export default function Footer({logo, navigationLeft, navigationRight, locale, copyright} : IFooter) {
    return (
        <footer className="bg-primary py-2">
            <div className="container text-center">
                <nav className="flex max-sm:flex-col sm:justify-center sm:items-center sm:py-2 sm:w-full">
                    <ul className="max-sm:order-2 sm:flex flex-1 items-center sm:justify-end">
                        <li className="sm:mx-2">
                            <NavLink to='/'
                                className="block mt-2 py-1 underline-anim underline-animate text-white font-medium sm:text-sm">
                                Koti
                            </NavLink>
                        </li>
                        {navigationLeft && navigationLeft.length && navigationLeft.map((item : any, i : number) =>
                        <li className="sm:mx-2" key={i}>
                            <NavLink to={locale === 'fi' ? `/${item.title?.toLowerCase()}` : `/${locale}/${item.title?.toLowerCase()}`}
                                className="block mt-2 py-1 underline-anim underline-animate text-white font-medium text-sm">
                        {item.menuTitle}
                            </NavLink>
                        </li>)}
                    </ul>
                    <div className="max-sm:order-1 sm:mx-4 mx-auto">
                        <NavLink to="/">
                            {logo && <img src={logo.url} aria-label="logo" width="100px" />}
                        </NavLink>
                    </div>
                    <ul className="max-sm:order-2 sm:flex flex-1 items-center justify-start">
                        {navigationRight && navigationRight.length && navigationRight.map((item : any, i : number) =>
                        <li className="sm:mx-2" key={i}>
                            <NavLink to={locale === 'fi' ? `/${item.title?.toLowerCase()}` : `/${locale}/${item.title?.toLowerCase()}`}
                                className="block mt-2 py-1 underline-anim underline-animate text-white font-medium text-sm">
                        {item.menuTitle}
                            </NavLink>
                        </li>)}
                    </ul>
                </nav>
                <SocialMedia className="justify-center text-white mt-3" />
                <hr className="mt-2 bg-secondary opacity-10" />
                <p className="pt-3 text-center text-white text-sm">{copyright}</p>
            </div>
        </footer>
    )
}