
import {
  Links,
  LiveReload,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useLocation,
} from "@remix-run/react";
import Header from './components/Header'
import Footer from './components/Footer'
import { json, LoaderArgs } from "@remix-run/cloudflare";
import { useLoaderData } from "@remix-run/react";
import tailwind from "./styles/tailwind.css";
import styles from "./styles/main.css";
import { client, configureClient } from "./models/contentful.server";



export const meta = () => ({
  charset: "utf-8",
  title: "Cannoli",
  description: "Meet Cannoli, the smart cuddly creature that everyone loves!",
  viewport: "width=device-width,initial-scale=1",
});

export async function loader(args : LoaderArgs) {
  configureClient(args.context.CONTENTFUL_SPACE_ID, args.context.CONTENTFUL_ACCESS_TOKEN);
  let locale : string;
  let url = args?.request?.url;

  if (url.includes('en')) {
    locale = 'en';
  } else {
    locale = 'fi';
  }

	return json({
    data : await client.getLayout("Default layout")
  });
}

export const links = () => [
  {rel: "stylesheet", href: tailwind},
  {rel: "stylesheet", href: styles},
]

export default function App() {
	const { data, locale } = useLoaderData();
  console.log(data);
  return (
    <html lang="en">
      <head>
        <Meta />
        <Links />
      </head>
      <body>
          <div className="flex flex-col min-h-screen">
            <Header logo={data?.logo} navigationLeft={data?.navigationLeftCollection.items} navigationRight={data?.navigationRightCollection.items} locale={locale || ''} />
            <div className="flex-1">
              <Outlet />
            </div>
            <Footer logo={data?.logo} navigationLeft={data?.navigationLeftCollection.items} navigationRight={data?.navigationRightCollection.items} locale={locale || ''} copyright={data?.copyright} />
          </div>
        <ScrollRestoration />
        <Scripts />
        <LiveReload />
      </body>
    </html>
  );
}
